@font-face {
     font-family: 'DM Sans';
     src: url('../../public/font/dm-sans/DMSans-Regular.eot');
     src: local('DM Sans Regular'), local('DMSans-Regular'),
         url('../../public/font/dm-sans/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/dm-sans/DMSans-Regular.woff2') format('woff2'),
         url('../../public/font/dm-sans/DMSans-Regular.woff') format('woff'),
         url('../../public/font/dm-sans/DMSans-Regular.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'DM Sans';
     src: url('../../public/font/dm-sans/DMSans-Medium.eot');
     src: local('DM Sans Medium'), local('DMSans-Medium'),
         url('../../public/font/dm-sans/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/dm-sans/DMSans-Medium.woff2') format('woff2'),
         url('../../public/font/dm-sans/DMSans-Medium.woff') format('woff'),
         url('../../public/font/dm-sans/DMSans-Medium.ttf') format('truetype');
     font-weight: 500;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'DM Sans';
     src: url('../../public/font/dm-sans/DMSans-MediumItalic.eot');
     src: local('DM Sans Medium Italic'), local('DMSans-MediumItalic'),
         url('../../public/font/dm-sans/DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/dm-sans/DMSans-MediumItalic.woff2') format('woff2'),
         url('../../public/font/dm-sans/DMSans-MediumItalic.woff') format('woff'),
         url('../../public/font/dm-sans/DMSans-MediumItalic.ttf') format('truetype');
     font-weight: 500;
     font-style: italic;
 }
 
 @font-face {
     font-family: 'DM Sans';
     src: url('../../public/font/dm-sans/DMSans-BoldItalic.eot');
     src: local('DM Sans Bold Italic'), local('DMSans-BoldItalic'),
         url('../../public/font/dm-sans/DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/dm-sans/DMSans-BoldItalic.woff2') format('woff2'),
         url('../../public/font/dm-sans/DMSans-BoldItalic.woff') format('woff'),
         url('../../public/font/dm-sans/DMSans-BoldItalic.ttf') format('truetype');
     font-weight: bold;
     font-style: italic;
 }
 
 @font-face {
     font-family: 'DM Sans';
     src: url('../../public/font/dm-sans/DMSans-Italic.eot');
     src: local('DM Sans Italic'), local('DMSans-Italic'),
         url('../../public/font/dm-sans/DMSans-Italic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/dm-sans/DMSans-Italic.woff2') format('woff2'),
         url('../../public/font/dm-sans/DMSans-Italic.woff') format('woff'),
         url('../../public/font/dm-sans/DMSans-Italic.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
 }
 
 @font-face {
     font-family: 'DM Sans';
     src: url('../../public/font/dm-sans/DMSans-Bold.eot');
     src: local('DM Sans Bold'), local('DMSans-Bold'),
         url('../../public/font/dm-sans/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/dm-sans/DMSans-Bold.woff2') format('woff2'),
         url('../../public/font/dm-sans/DMSans-Bold.woff') format('woff'),
         url('../../public/font/dm-sans/DMSans-Bold.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'PT Sans';
     src: url('../../public/font/pt-sans/PTSans-Regular.eot');
     src: local('PT Sans'), local('PTSans-Regular'),
         url('../../public/font/pt-sans/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/pt-sans/PTSans-Regular.woff2') format('woff2'),
         url('../../public/font/pt-sans/PTSans-Regular.woff') format('woff'),
         url('../../public/font/pt-sans/PTSans-Regular.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'PT Sans';
     src: url('../../public/font/pt-sans/PTSans-BoldItalic.eot');
     src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'),
         url('../../public/font/pt-sans/PTSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/pt-sans/PTSans-BoldItalic.woff2') format('woff2'),
         url('../../public/font/pt-sans/PTSans-BoldItalic.woff') format('woff'),
         url('../../public/font/pt-sans/PTSans-BoldItalic.ttf') format('truetype');
     font-weight: bold;
     font-style: italic;
 }
 
 @font-face {
     font-family: 'PT Sans';
     src: url('../../public/font/pt-sans/PTSans-Italic.eot');
     src: local('PT Sans Italic'), local('PTSans-Italic'),
         url('../../public/font/pt-sans/PTSans-Italic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/pt-sans/PTSans-Italic.woff2') format('woff2'),
         url('../../public/font/pt-sans/PTSans-Italic.woff') format('woff'),
         url('../../public/font/pt-sans/PTSans-Italic.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
 }
 
 @font-face {
     font-family: 'PT Sans';
     src: url('../../public/font/pt-sans/PTSans-Bold.eot');
     src: local('PT Sans Bold'), local('PTSans-Bold'),
         url('../../public/font/pt-sans/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/pt-sans/PTSans-Bold.woff2') format('woff2'),
         url('../../public/font/pt-sans/PTSans-Bold.woff') format('woff'),
         url('../../public/font/pt-sans/PTSans-Bold.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-Light.eot');
     src: local('Rockwell Std Light'), local('RockwellStd-Light'),
         url('../../public/font/rockwell/RockwellStd-Light.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-Light.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-Light.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-Light.ttf') format('truetype');
     font-weight: 300;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-BoldItalic.eot');
     src: local('Rockwell Std Bold Italic'), local('RockwellStd-BoldItalic'),
         url('../../public/font/rockwell/RockwellStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-BoldItalic.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-BoldItalic.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-BoldItalic.ttf') format('truetype');
     font-weight: bold;
     font-style: italic;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-Italic.eot');
     src: local('Rockwell Std Italic'), local('RockwellStd-Italic'),
         url('../../public/font/rockwell/RockwellStd-Italic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-Italic.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-Italic.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-Italic.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-LightItalic.eot');
     src: local('Rockwell Std Light Italic'), local('RockwellStd-LightItalic'),
         url('../../public/font/rockwell/RockwellStd-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-LightItalic.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-LightItalic.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-LightItalic.ttf') format('truetype');
     font-weight: 300;
     font-style: italic;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-ExtraBold.eot');
     src: local('Rockwell Std Extra Bold'), local('RockwellStd-ExtraBold'),
         url('../../public/font/rockwell/RockwellStd-ExtraBold.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-ExtraBold.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-ExtraBold.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-ExtraBold.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-BoldCondensed.eot');
     src: local('Rockwell Std Bold Condensed'), local('RockwellStd-BoldCondensed'),
         url('../../public/font/rockwell/RockwellStd-BoldCondensed.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-BoldCondensed.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-BoldCondensed.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-BoldCondensed.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-Condensed.eot');
     src: local('Rockwell Std Condensed'), local('RockwellStd-Condensed'),
         url('../../public/font/rockwell/RockwellStd-Condensed.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-Condensed.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-Condensed.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-Condensed.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd.eot');
     src: local('Rockwell Std Regular'), local('RockwellStd'),
         url('../../public/font/rockwell/RockwellStd.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Rockwell Std';
     src: url('../../public/font/rockwell/RockwellStd-Bold.eot');
     src: local('Rockwell Std Bold'), local('RockwellStd-Bold'),
         url('../../public/font/rockwell/RockwellStd-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/rockwell/RockwellStd-Bold.woff2') format('woff2'),
         url('../../public/font/rockwell/RockwellStd-Bold.woff') format('woff'),
         url('../../public/font/rockwell/RockwellStd-Bold.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'The Nautigal';
     src: url('../../public/font/the-nautigal/TheNautigal-Bold.eot');
     src: local('The Nautigal Bold'), local('TheNautigal-Bold'),
         url('../../public/font/the-nautigal/TheNautigal-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/the-nautigal/TheNautigal-Bold.woff2') format('woff2'),
         url('../../public/font/the-nautigal/TheNautigal-Bold.woff') format('woff'),
         url('../../public/font/the-nautigal/TheNautigal-Bold.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'The Nautigal';
     src: url('../../public/font/the-nautigal/TheNautigal-Regular.eot');
     src: local('The Nautigal Regular'), local('TheNautigal-Regular'),
         url('../../public/font/the-nautigal/TheNautigal-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../public/font/the-nautigal/TheNautigal-Regular.woff2') format('woff2'),
         url('../../public/font/the-nautigal/TheNautigal-Regular.woff') format('woff'),
         url('../../public/font/the-nautigal/TheNautigal-Regular.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
     font-display: swap;
 }
 
 